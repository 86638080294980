.login-modal {
  .ant-modal-content {
    .ant-modal-body {
      padding: 0;
      .login-body {
        padding: 24px;
        .ant-btn {
          width: 100%;
          margin-top: 5px;
        }
        .facebook-button{
          color: #fff;
          background: #1890ff;
          border-color: #1890ff;
          text-shadow: 0 -1px 0 rgba(0,0,0,.12);
          -webkit-box-shadow: 0 2px 0 rgba(0,0,0,.045);
          box-shadow: 0 2px 0 rgba(0,0,0,.045);
        }
        .google-button{
          width: 100%;
        }
      }
    }
  }
}
