.game-detail-summary {
  .ant-col{
    padding: 5px;
    text-align: center;
    h4{
      margin-bottom: 0px;
    }
  }
  .ant-col-1{
    width: 1px;
    padding-top: 15px;
    padding-bottom: 15px;
    div {
      width: 20%;
      height: 100%;;
      background: #afafaf;;
    }
  }
}