.gq-container {
  padding-top: 20px;
  padding-left: 200px;
  padding-right: 200px;
}
@media screen and (max-width: 800px) {
  .gq-container {
    padding-top: 20px;
    padding-left: 20px!important;
    padding-right: 20px!important;
  }
}