.game-card {
  .ant-card-body {
    padding: 0!important;
    .ant-image {
      img {
        border-radius: 10px 10px 10px 10px;
        object-fit: cover;  
      }
    }
  }
}
.image-container {
  overflow-x: auto;
  overflow: auto;
  white-space: nowrap;
  .game-card {
    display: inline-block;
    text-align: center;
    margin: 10px;
    text-decoration: none;
  }
}