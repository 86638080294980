.center-content {
  text-align: center;
}
.profile-card {
  border-radius: 50px !important;
  width: 100px;
  height: 100px;
  padding: 0!important;
  .ant-card-body {
    padding: 0!important;
    .ant-image {
      img {
        border-radius: 50px;
      object-fit: cover;
        
      }
    }
  }
}


.leaderboard-card {
  border-radius: 10px !important;
  box-shadow: 0px 3px 3px 3px rgba(41,41,41,.25);
  margin-top: 10px;
  margin-bottom: 10px;
  .ant-col{
    text-align: center;
  }
  
}
.leaderboard-header {
  background: #00875F;
  .ant-typography{
    color: #fff;
  
  }
}