

.event-card {
  border-radius: 10px !important;
  box-shadow: 0px 0px 9px 3px rgba(41,41,41,.25);
  margin-top: 10px;
  margin-bottom: 10px;
  table td {
    vertical-align: top;
  }
  span.ant-typography {
    font-size: 10pt;
  }
  .ant-card-body {
    padding: 0!important;
    .ant-image {
      img {
        border-radius: 10px 10px 0 0;
      object-fit: cover;
        
      }
      img:before {
        content: "";
        display: block;
        padding-top: 100%;
      }
    }
    span {
      margin-left: 10px;
      margin-right: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      ;
      // display: inline-block;
    }
    
    .span::after {
      content: "...";
      position: absolute;
      right: -12px; 
      bottom: 4px;
    }
  }
}

.center-content {
  min-height: 100px;
  text-align: center;
  a {

    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

}
