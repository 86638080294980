.ant-layout-header {
  .ant-menu {
    .ant-menu-item {
      .ant-menu-item-active {
        // border-bottom: none!important;
      }
    }
    .ant-menu-item:hover {
      border-bottom: 0px!important;
    }
    .ant-menu-item:not(:hover) {
      border-bottom: 0px!important;
    }
    .ant-menu-item-selected {
      border-bottom: 0px!important;
    }
  }
}
.ant-menu-item .anticon, .ant-menu-submenu-title .anticon {
  margin-right: 0px !important;
}